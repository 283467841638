import { Box, CircularProgress } from '@mui/material'
import CardItem from 'components/CardItem'
import usePath from 'hooks/usePath'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, GenericDataType, HouseHotApiService, VehicleSearchApiService } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { ProductType, generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

type Props = {
  typeRental?: string
  type?: string
}

const SimilarProducts: React.FC<Props> = ({ typeRental, type }) => {
  const { path } = usePath()
  const history = useHistory()
  const { t } = useTranslation('detail')
  const { data, isLoading, refetch } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: { page: 1, limit: 4, type_rental: typeRental } as ApiParamsProps,
    options: {
      enabled:
        path.includes(ROUTES.HOUSE) ||
        path === ROUTES.FOR_RENT ||
        path.includes(ROUTES.NEED_SELL) ||
        path.includes(ROUTES.PROJECT),
    },
  })

  const {
    data: dataVehicle,
    isLoading: isLoadingVehicle,
    refetch: refetchVehicle,
  } = VehicleSearchApiService.useGet<GenericDataType<ItemType>>({
    params: { page: 1, limit: 4, type: type } as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.VEHICLE),
    },
  })

  const filteredData = React.useMemo(() => {
    if (path.includes(ROUTES.VEHICLE)) return dataVehicle?.data?.result
    return data?.data?.result
  }, [data?.data?.result, dataVehicle?.data?.result, path])

  return (
    <Box className="bg-white rounded-xl p-8">
      <Box className="text-content-18 font-bold text-brand-1 mb-2">{t('similar_product')}</Box>
      <Box className="mt-8">
        <Swiper
          modules={[Pagination]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>'
            },
          }}
          breakpoints={{
            350: {
              slidesPerView: 1.2,
            },
            600: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            980: {
              slidesPerView: 4,
            },
            1140: {
              slidesPerView: 5,
            },
          }}
        >
          {filteredData?.map((item, index) => (
            <SwiperSlide className="!h-[500px]" key={index}>
              <Box className="p-2">
                <CardItem
                  id={item._id}
                  itemData={item}
                  key={index}
                  type={item.type}
                  name={item.name}
                  image={item?.images?.[0]?.image || ''}
                  bathCount={item.n_bathroom}
                  price={item.sale_price || item.price}
                  type_rental={item.type_rental}
                  bedCount={item.n_bedroom}
                  updatedTime={item.updated_at}
                  isLiked={item?.is_favorite}
                  onRefetchAfterLike={() => (ProductType(item.type) === 'HOUSE' ? refetch() : refetchVehicle())}
                  href={generateHref({ item: item })}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default SimilarProducts
