import { Box } from '@mui/material'
import CardCarousel, { CarouselType } from 'components/CardCarousel'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, BDSNewsApiService, GenericDataType } from 'services'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import ROUTES from 'utils/constants/routesConstant'
import { NewsItem } from 'utils/types'
import TitleWithRoute, { TitleWithRouteType } from '../../../components/TitleWithRoute'

const BDSNews = () => {
  const { t } = useTranslation('home')

  const { data, isLoading } = BDSNewsApiService.useGet<GenericDataType<NewsItem>>({
    params: {
      page: 1,
      limit: 6,
    } as ApiParamsProps,
  })

  return (
    <Box className="mt-4">
      <TitleWithRoute
        buttonText={t('find_more_news')}
        href={`${ROUTES.NEWS}?page=1`}
        title={t('bds_news')}
        type={TitleWithRouteType.BACKGROUND_BUTTON}
      />
      <Box className="mt-8">
        <Swiper
          modules={[Pagination]}
          spaceBetween={18}
          slidesPerView={3}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>'
            },
          }}
        >
          {data?.data &&
            data?.data?.result?.map((item, index) => (
              <SwiperSlide className="!h-[350px]" key={index}>
                <CardCarousel
                  type={CarouselType.NEW}
                  title={item.title}
                  starCount={5}
                  description={item.title}
                  index={index}
                  href={`${ROUTES.NEWS}/${item.alias}/${item._id}`}
                  srcImage={''}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default BDSNews
