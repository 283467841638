import { Radio, RadioProps, styled } from '@mui/material'
import Check from 'assets/images/check.png'
import Strick from 'assets/images/Strick.png'

interface Props extends RadioProps {
  typeSelect?: 'label' | 'check'
  nameOfCheck?: string
}

const BpIcon = styled('span')(({ theme }) => ({
  width: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 16,
  backgroundColor: '#eeeeee',
  border: '1px solid #3a3a3c',
  borderRadius: '4px',
  '.Mui-focusVisible &': {
    outline: '2px auto #DF3E23',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#eeeeee',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#cccccc',
  },
}))

const BpIconLabel = styled('span')<{ label?: string }>(({ theme, label }) => ({
  width: 49,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 28,
  backgroundColor: '#eeeeee',
  border: '1px solid #3a3a3c',
  '&:after': {
    content: `"${label}"`,
  },
  borderRadius: '8px',
  '.Mui-focusVisible &': {
    outline: '2px auto #DF3E23',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#eeeeee',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#cccccc',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundImage: `url(${Check})`,
  '&:before': {
    display: 'block',
    width: 8,
    height: 8,
    backgroundImage: `url(${Check})`,
    // backgroundColor: '#DF3E23',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#eeeeee',
  },
})

const BpCheckedIconLabel = styled(BpIcon)<{ label?: string }>(({ theme, label }) => ({
  width: 49,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 28,
  backgroundColor: '#eeeeee',
  border: '1px solid #3a3a3c',
  borderRadius: '8px',
  position: 'relative',
  '&:after': {
    content: `"${label}"`,
  },
  '&:before': {
    display: 'block',
    position: 'absolute',
    bottom: -1,
    right: -1,
    width: 15,
    height: 15,
    backgroundImage: `url(${Strick})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#eeeeee',
  },
}))

const CheckedControl = (props: Props) => {
  switch (props.typeSelect) {
    case 'check':
      return <Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />
    case 'label':
      return (
        <Radio
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIconLabel label={props?.nameOfCheck} />}
          icon={<BpIconLabel label={props?.nameOfCheck} />}
          {...props}
        />
      )
    default:
      return null
  }
}

export default CheckedControl
