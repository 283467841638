import React from 'react'
import './App.css'
import Routes from './routes'
import MainLayout from './layouts'
import { ACTION_TYPE, Actions, LoginModalStatus, loginModalContext, loginModalDispatchContext } from './context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'utils/constants'

const profileReducer = (state: LoginModalStatus, action: Actions) => {
  const { type, payload } = action
  let nextState = { ...state }
  switch (type) {
    case ACTION_TYPE.INIT:
      nextState = !!payload ? { ...nextState, ...payload } : ({} as LoginModalStatus)
      return nextState
    default:
      return state
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /*control refetch when focus on window*/
      refetchOnWindowFocus: false,
      /*Queries that fail are silently retried 3 times, with exponential backoff delay before capturing and displaying an error to the UI if retry = true*/
      retry: false,
      cacheTime: 0,
      /*default staleTime means queries will not refetch their data as often*/
      // staleTime: 5 * 60 * 1000,
    },
  },
})

function App() {
  const [profile, dispatch] = React.useReducer(profileReducer, {} as LoginModalStatus)

  return (
    <loginModalContext.Provider value={profile}>
      <loginModalDispatchContext.Provider value={dispatch}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            autoHideDuration={1000}
          >
            <SnackbarUtilsConfigurator />
            <MainLayout>
              <Routes />
            </MainLayout>
          </SnackbarProvider>
        </QueryClientProvider>
      </loginModalDispatchContext.Provider>
    </loginModalContext.Provider>
  )
}

export default App
