import { Box, CircularProgress } from '@mui/material'
import CardItem from 'components/CardItem'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, GenericDataType, HouseHotApiService } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

const LongTimePlace = () => {
  const { data, isLoading, refetch } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 5,
      type_rental: 'MONTH',
    } as ApiParamsProps,
  })

  const { t } = useTranslation('home')
  const history = useHistory()

  const filteredData = React.useMemo(() => {
    if (data?.data?.result && data?.data?.result?.length) {
      return data?.data?.result
    } else return []
  }, [data])

  return (
    <Box>
      <TitleWithRoute
        buttonText={t('watch_all')}
        href={`${ROUTES.FOR_RENT}?page=1&type_rental=MONTH`}
        title={
          <>
            <Box className="inline-block mb-3 text-white text-content-title font-bold bg-brand-1 px-6 py-2 rounded-tr-xl rounded-br-xl MuiBox-root css-0">
              {t('long_time_place_title')}
            </Box>
            <Box className="text-grey-1">{t('long_time_place_detail', { counter: data?.data?.total })}</Box>
          </>
        }
        type={TitleWithRouteType.TRANSPARENT_BACKGROUND}
      />
      {isLoading ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex flex-wrap gap-5">
          {filteredData.map((item, index) => (
            <CardItem
              key={index}
              itemData={item}
              id={item._id}
              type={item.type}
              name={item.name}
              image={item?.images?.[0]?.image || ''}
              bathCount={item.n_bathroom}
              bedCount={item.n_bedroom}
              updatedTime={item.updated_at}
              price={item?.sale_price || item?.price}
              isLiked={item?.is_favorite}
              type_rental={item.type_rental}
              onRefetchAfterLike={() => refetch()}
              href={generateHref({ item: item })}
            />
          ))}
        </Box>
      )}
      {!isLoading && !data?.data?.total && (
        <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>
      )}
    </Box>
  )
}

export default LongTimePlace
