import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { lineVehicle, statusVehicle, typeCard, typeFuel, warrantyVehicle } from 'utils/constants'
import { ProductType } from 'utils/functions'
import { ItemType } from 'utils/types'
import listIcon from 'utils/constants/iconConstant'

export enum DetailType {
  HOUSE = 1,
  VEHICLE = 2,
}

type Props = {
  data?: ItemType
}

export type Properties = {
  [key: string]: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const DetailTab = ({ data }: { data: Properties }) => {
  const { t } = useTranslation('detail')

  const mappingData = React.useMemo(() => {
    if (data) {
      const keys = Object.keys(data)
      return keys.map(item => ({
        icon: listIcon[item],
        label: t(item),
        value: data[item],
      }))
    }
  }, [data, t])
  return (
    <Box className="w-full mt-6">
      <Box className="flex flex-wrap gap-6">
        {mappingData?.map((item, index) => (
          <Box
            key={index}
            className="flex max-w-[33%] min-w-[300px] w-full text-content-14 border-b-2 pb-2 items-center"
          >
            <Box className="flex min-w-[200px] text-grey-1 items-center gap-1">
              <Box>{item.icon}</Box>
              <Box>{item.label}</Box>
            </Box>
            <Box className="font-bold">{item.value}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const DescriptionTab = ({ data }: { data?: string }) => {
  return <Box className="w-full mt-6">{data}</Box>
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DetailInformation: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('detail')
  const [value, setValue] = React.useState(0)

  const vehicleDetail = React.useMemo(() => {
    return {
      type_of_vehicle_1: t(typeCard?.VEHICLE?.find(x => x === data?.type)?.toLowerCase() || ''),
      vehicle_line: t(lineVehicle.find(x => x === data?.vehicle_line)?.toLowerCase() || ''),
      n_km_traveled: data?.n_km_traveled,
      origin: data?.origin,
      tonnage: data?.tonnage,
      firms: data?.firms,
      n_year_manufacture: data?.n_year_manufacture,
      status: t(statusVehicle.find(x => x === data?.status)?.toLowerCase() || ''),
      fuel: t(typeFuel.find(x => x === data?.fuel)?.toLowerCase() || ''),
      warranty_policy: t(warrantyVehicle.find(x => x === data?.warranty_policy)?.toLowerCase() || ''),
      license_plate: data?.license_plate,
    }
  }, [data, t])

  return (
    <Box className="bg-white rounded-xl p-8">
      <Tabs value={value} onChange={(_, value) => setValue(value)} aria-label="basic tabs example">
        <Tab
          label={t('detail_information')}
          {...a11yProps(0)}
          className="!text-brand-1 !font-bold !normal-case !text-content-18"
        />
        <Tab
          label={t('description')}
          {...a11yProps(1)}
          className="!text-brand-1 !font-bold !normal-case !text-content-18"
        />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <DetailTab data={vehicleDetail} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DescriptionTab data={data?.description} />
      </CustomTabPanel>
    </Box>
  )
  // }
}

export default DetailInformation
