import { AttachMoneyOutlined, DirectionsCarOutlined, HomeOutlined, VillaOutlined } from '@mui/icons-material'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import FilterBackground from 'assets/images/filterBackground.png'
import usePath from 'hooks/usePath'
import { useQueryParams } from 'hooks/useQueryParams'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultApiService, GenericDataType, HouseApiService } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import FilterForProject from './FilterForProject'
import FilterForRent from './FilterForRent'
import FilterForVehicle from './FilterForVehicle'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'var(--color-main)',
            fontWeight: 'bold',
          },
          color: '#ADB3BC',
          textTransform: 'none',
          fontSize: '16px',
        },
      },
    },
  },
})

export enum TabsType {
  RENT = 0,
  FOR_SALE = 1,
  PROJECT = 2,
  VEHICLE = 3,
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export type OptionsFilter = {
  type_rental?: string[]
  house_type?: string[]
  vehicle_type?: string[]
  gender?: string[]
}

export type OptionsAddress = {
  code: string
  image: string
  lat: number
  long: number
  name: string
  name_with_type: string
  parent_code: string
  path_with_type: string
  _id: string
  project?: string[]
}

export type Option = {
  label: string
  value: string
}

export type ListAutocomplete = {
  name: string
  label: string
  options: Option[]
  placeholder: string
  isLoading: boolean
  defaultValue?: string
}

const Filter = () => {
  const { t } = useTranslation('home')
  const { data, isLoading } = DefaultApiService.useGet<GenericDataType<OptionsFilter>>({
    url: '/master-data',
  })
  const { path } = usePath()

  let tabInit = TabsType.RENT

  switch (path) {
    case ROUTES.FOR_RENT:
      tabInit = TabsType.RENT
      break
    case ROUTES.NEED_SELL:
      tabInit = TabsType.FOR_SALE
      break
    case ROUTES.PROJECT:
      tabInit = TabsType.PROJECT
      break
    case ROUTES.VEHICLE:
      tabInit = TabsType.VEHICLE
      break
    default:
      break
  }
  const [tab, setTab] = useState(tabInit)

  const currentParams = useQueryParams()

  const { data: dataAddress, isLoading: isLoadingAddress } = DefaultApiService.useGet<GenericDataType<OptionsAddress>>({
    url: '/cadastral/district/31',
  })

  const { data: dataProject, isLoading: isLoadingProject } = HouseApiService.useGet<GenericDataType<OptionsAddress>>({
    url: '/project',
  })

  const optionsTimeStay: { label: string; value: string }[] = React.useMemo(() => {
    const res = data?.data?.result?.[0]
    if (res?.type_rental) {
      return tab === TabsType.FOR_SALE
        ? res?.type_rental?.map(item => ({ label: t(item?.toLowerCase()), value: item }))
        : res?.type_rental
            ?.filter(item => item !== 'FOREVER')
            .map(item => ({ label: t(item?.toLowerCase()), value: item }))
    }
    return []
  }, [data?.data?.result, t, tab])

  const optionsType: { label: string; value: string }[] = React.useMemo(() => {
    const res = data?.data?.result?.[0]
    if (res?.house_type) {
      return res?.house_type?.map(item => ({ label: t(item?.toLowerCase()), value: item }))
    }
    return []
  }, [data?.data?.result, t])

  const optionsArea: { label: string; value: string }[] = React.useMemo(() => {
    if (dataAddress?.data?.result) {
      return dataAddress?.data?.result?.map(item => ({ label: item?.name_with_type, value: item?.code }))
    }
    return []
  }, [dataAddress?.data?.result])

  const listAutocomplete: ListAutocomplete[] = React.useMemo(
    () => [
      {
        name: 'project_name',
        label: t('project'),
        options: dataProject?.data.result[0].project?.map(i => ({ label: i, value: i })) || [],
        placeholder: t('project'),
        isLoading: isLoadingProject,
        defaultValue: dataProject?.data.result[0].project?.[0] || '',
      },
      {
        name: 'district',
        label: t('Giá từ'),
        options: optionsArea,
        placeholder: t('area_placeholder'),
        isLoading: isLoadingAddress,
        defaultValue: currentParams?.district,
      },
      {
        name: 'type_rental',
        label: t('Kích cỡ'),
        options: optionsTimeStay,
        placeholder: t('time_stay_placeholder'),
        isLoading: isLoading,
      },
      {
        name: 'type_rental',
        label: t('Màu sắc'),
        options: optionsTimeStay,
        placeholder: t('time_stay_placeholder'),
        isLoading: isLoading,
      },
    ],
    [
      t,
      dataProject?.data.result,
      isLoadingProject,
      optionsArea,
      isLoadingAddress,
      currentParams,
      optionsType,
      isLoading,
      optionsTimeStay,
    ],
  )

  const listTabsContent = React.useMemo(
    () => [
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      {
        component: (
          <FilterForRent
            key={0}
            route={ROUTES.FOR_RENT}
            listAutocomplete={listAutocomplete.slice(1, 5)}
            isLoading={isLoading}
            currentParams={currentParams}
          />
        ),
      },
      // {
      //   component: (
      //     <FilterForRent
      //       key={1}
      //       currentParams={currentParams}
      //       route={ROUTES.NEED_SELL}
      //       listAutocomplete={listAutocomplete.slice(1, 4)}
      //       isLoading={isLoading}
      //     />
      //   ),
      // },
      // {
      //   component: (
      //     <FilterForProject
      //       key={2}
      //       route={ROUTES.PROJECT}
      //       listAutocomplete={listAutocomplete.slice(1, 4)}
      //       isLoading={isLoading}
      //       currentParams={currentParams}
      //     />
      //   ),
      // },
      // {
      //   component: <FilterForVehicle key={3} />,
      // },
    ],
    [currentParams, isLoading, listAutocomplete],
  )

  const listTabs = React.useMemo(
    () => [
      {
        label: t('txt-vay-va-dam'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('txt-chan-vay'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('ao-thun'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('ao-so-mi'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('do-bo'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('Giày dép'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('phu-kien'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      {
        label: t('xem-them'),
        value: TabsType.RENT,
        // icon: <HomeOutlined />,
        url: ROUTES.FOR_RENT,
      },
      // {
      //   label: t('txt-chan-vay'),
      //   value: TabsType.FOR_SALE,
      //   icon: <AttachMoneyOutlined />,
      //   url: ROUTES.NEED_SELL,
      // },
      // {
      //   label: t('project'),
      //   value: TabsType.PROJECT,
      //   icon: <VillaOutlined />,
      //   url: ROUTES.PROJECT,
      // },
      // {
      //   label: t('vehicle_for_rent_drive_yourself'),
      //   value: TabsType.VEHICLE,
      //   icon: <DirectionsCarOutlined />,
      // },
    ],
    [t],
  )

  const handleChange = (e: React.SyntheticEvent, value: number) => {
    setTab(value)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="h-[600px] desktop:w-full flex items-center justify-center"
        sx={{
          backgroundImage: `url(${FilterBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box className="bg-white min-h-[236px] rounded-xl desktop:px-8 py-6 flex items-center flex-col desktop:max-w-[1248px] w-full flex-wrap">
          <Tabs
            className="!min-h-0 !mx-auto"
            value={tab}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: 'var(--color-main)',
              },
            }}
            variant="scrollable"
            scrollButtons
          >
            {listTabs.map((item, index) => (
              <Tab
                className="!min-h-0 !px-0 desktop:!mx-5 !text-content-16"
                label={item.label}
                {...a11yProps(index)}
                key={index}
                // icon={item.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
          <Divider className="!mt-6 w-full" />
          <Box className="flex flex-wrap">
            {listTabsContent.map((item, index) => (
              <TabPanel index={index} value={tab} key={index}>
                {item.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Filter
