import { Box, CircularProgress } from '@mui/material'
import flashSalePNG from 'assets/images/flash-sale.png'
import playIcon from 'assets/images/play.svg'
import SaleIcon from 'assets/images/sale.png'
import CardItem from 'components/CardItem'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { domain } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

type Props = {
  data?: ItemType[]
  isLoading?: boolean
  counter: number
  title?: string
  timeSale?: string
  video?: string
  refetch?: () => void
}

const FlashSaleInDay: React.FC<Props> = ({ timeSale, isLoading, data, counter, title, video, refetch }) => {
  const { t } = useTranslation('home')
  const history = useHistory()

  const [isOpen, setOpen] = useState(false)

  const idVideo = React.useMemo(() => {
    if (video?.includes('youtube')) {
      return video.replace('https://www.youtube.com/watch?v=', '')
    }
  }, [video])

  const filteredData = React.useMemo(() => {
    if (data && data?.length) {
      if (video) return data.slice(0, 3)
      return data
    } else return []
  }, [data, video])

  return (
    <Box>
      <TitleWithRoute
        buttonText={t('watch_all')}
        href={`${ROUTES.FLASH_SALE_IN_DAY_COMMING}?page=1`}
        title={
          <Box className="flex items-center gap-4">
            <Box component={'img'} src={SaleIcon} alt="" />
            <Box className="text-content-title font-bold rounded-tr-xl rounded-br-xl">Flash sales trong ngày</Box>
          </Box>
        }
        timeSale={timeSale}
        type={TitleWithRouteType.FLASH_SALE}
      />
      {isLoading ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex flex-wrap gap-4">
          {video && (
            <Box className="w-[516px] h-[437px] flex justify-center items-center bg-black rounded-xl">
              {isOpen ? (
                <>
                  {video?.includes('youtube') ? (
                    <iframe
                      className="w-full h-full"
                      title="Youtube player"
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://youtube.com/embed/${idVideo}?autoplay=0`}
                    ></iframe>
                  ) : (
                    <video controls={true} width={'100%'} height={'100%'} className="w-full h-full">
                      <source src={domain + video} type="video/mp4" />
                      <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"></track>
                    </video>
                  )}
                </>
              ) : (
                <div className="cursor-pointer w-full h-full relative" onClick={() => setOpen(true)}>
                  <img src={flashSalePNG} alt="" />
                  <img
                    src={playIcon}
                    alt=""
                    className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              )}
            </Box>
          )}
          {filteredData.map((item, index) => (
            <CardItem
              id={item._id}
              itemData={item}
              key={index}
              type={item.type}
              name={item.name}
              image={item?.images?.[0]?.image || ''}
              bathCount={item.n_bathroom}
              bedCount={item.n_bedroom}
              updatedTime={item.updated_at}
              href={generateHref({
                item: item,
              })}
              isLiked={item?.is_favorite}
              onRefetchAfterLike={() => refetch?.()}
              type_rental={item.type_rental}
              price={item?.sale_price || item?.price}
            />
          ))}
        </Box>
      )}
      {!isLoading && !counter && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
    </Box>
  )
}

export default FlashSaleInDay
