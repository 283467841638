import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const listPartner = [
  { logo: '/images/partner-1.png', text: 'Perfect Bath and Kitchen' },
  { logo: '/images/partner-2.png', text: 'Caesar' },
  { logo: '/images/partner-3.png', text: 'Abbott' },
  { logo: '/images/partner-4.png', text: 'Blackmores' },
  { logo: '/images/partner-5.png', text: 'Sanofi GEM' },
  { logo: '/images/partner-6.png', text: 'Abbeautyworld' },
]

const Partner = () => {
  const { t } = useTranslation('home')
  return (
    <Box className="py-16">
      <Box className="text-content-title font-bold text-black-1 text-center">{t('partner')}</Box>
      <Box className="flex flex-wrap items-center justify-around mt-5">
        {listPartner.map((item, index) => (
          <Box key={index} className="flex flex-col justify-center items-center">
            <Box className="w-[200px] h-[124px] flex justify-center items-center">
              <img src={item.logo} alt="" />
            </Box>
            <Box className="text-content-14">{item.text}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Partner
