/* eslint-disable prettier/prettier */
import { Box, CircularProgress, Pagination } from '@mui/material'
import CardItem from 'components/CardItem'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import Filter from 'components/Filter'
import usePath from 'hooks/usePath'
import { useQueryParams } from 'hooks/useQueryParams'
import { FlashSaleType } from 'pages/HomePage'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  ApiParamsProps,
  FlashSaleApiService,
  FlashSaleComingApiService,
  GenericDataType,
  HouseFavoriteApiService,
  HouseHotApiService,
  HouseSearchApiService,
  VehicleSearchApiService,
} from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

const services = [
  { route: ROUTES.HOUSE_HOT, service: HouseHotApiService },
  { route: ROUTES.HOUSE_FAVORITE, service: HouseFavoriteApiService },
  { route: ROUTES.VEHICLE, service: VehicleSearchApiService },
  { route: ROUTES.NEED_SELL, service: HouseSearchApiService },
  { route: ROUTES.PROJECT, service: HouseSearchApiService },
  { route: ROUTES.FOR_RENT, service: HouseSearchApiService },
]

const CategoryPage = () => {
  const currentParams = useQueryParams()
  const { path } = usePath()
  const history = useHistory()
  const { t } = useTranslation('home')
  const elm = React.useRef<any>()
  // console.log(services.find(x => path.includes(x.route)))
  // const currentService = services.filter(x => path.includes(x.route))[0]?.service || HouseHotApiService

  const currentService = services.filter(x => path.includes(x.route))[0]?.service

  const CurrentServiceResponse = currentService?.useGet<GenericDataType<ItemType>>({
    params: { ...currentParams } as ApiParamsProps,
    options: {
      enabled: !!services?.find(x => path.includes(x.route)),
    },
  })

  const {
    data: dataFlashSale,
    isLoading: isLoadingFlashSale,
    refetch: refetchFlashSale,
  } = FlashSaleComingApiService.useGet<FlashSaleType>({
    params: { commodity_type: 'HOUSE', ...currentParams } as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.FLASH_SALE_IN_DAY),
    },
  })

  const handleRefetch = React.useCallback(() => {
    if (path.includes(ROUTES.FLASH_SALE_IN_DAY)) return refetchFlashSale()
    return CurrentServiceResponse?.refetch()
  }, [CurrentServiceResponse, path, refetchFlashSale])

  const mappingData: ItemType[] = React.useMemo(() => {
    if (path.includes(ROUTES.FLASH_SALE_IN_DAY)) {
      if (dataFlashSale?.data?.result) {
        return dataFlashSale?.data?.result[0]?.data?.result
      }
      return []
    }
    if (CurrentServiceResponse?.data?.data?.result) {
      return CurrentServiceResponse?.data?.data?.result
    }
    return []
  }, [CurrentServiceResponse?.data?.data?.result, dataFlashSale?.data?.result, path])

  const handleChange = (_: any, page: number) => {
    const newParams = { ...currentParams, page }
    history.push(path + '?' + qs.stringify(newParams))
  }
  React.useEffect(() => {
    if (currentParams?.page && Number(currentParams?.page) !== 1) {
      window.scrollTo({ behavior: 'smooth', top: elm.current.offsetTop })
    }
  }, [currentParams])

  return (
    <Box>
      <Filter />
      <CustomBreadcrumbs />
      <Box className="pb-[136px] max-w-[1440px] mx-auto px-14 pt-14">
        {isLoadingFlashSale || CurrentServiceResponse?.isLoading ? (
          <Box className="flex justify-center my-8">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="flex flex-wrap gap-5">
            {mappingData?.map((item, index) => (
              <CardItem
                id={item._id}
                itemData={item}
                key={index}
                image={item?.images?.[0]?.image || ''}
                type={item.type}
                name={item.name}
                bathCount={item.n_bathroom}
                bedCount={item.n_bedroom}
                price={item.price_share || item.price}
                type_rental={item.type_rental}
                updatedTime={item.updated_at}
                onRefetchAfterLike={() => handleRefetch()}
                isLiked={item.is_favorite}
                href={generateHref({
                  item: item,
                })}
              />
            ))}
          </Box>
        )}
        {(!CurrentServiceResponse?.isLoading || !isLoadingFlashSale) && !mappingData?.length && (
          <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>
        )}
        {!!mappingData?.length && (
          <Box className="flex justify-center mt-8">
            <Pagination
              variant="outlined"
              count={CurrentServiceResponse?.data?.data?.totalPage || dataFlashSale?.data?.result[0]?.data?.totalPage}
              shape="rounded"
              onChange={handleChange}
              disabled={CurrentServiceResponse?.isLoading || isLoadingFlashSale}
              page={Number(currentParams?.page) || 1}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CategoryPage
