import { LocalMallOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import { Box, Button, ButtonBase, Divider, styled, RadioGroup, FormControlLabel, Select, MenuItem } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Social from './Social'

import { ItemType } from 'utils/types'
import { GeneralUtil } from 'helpers/GeneralUtil'
import CheckedControl from 'components/CheckedControl'

export type Info = {
  label?: string
  value?: string
}

type Props = {
  code?: number | string
  type?: string
  data?: ItemType
  refetch?: () => void
}

const StyledButton = styled(ButtonBase)({
  height: '48px',
  color: '#DF3E23',
  border: '1px solid #DF3E23',
  borderRadius: '12px',
  maxWidth: '220px',
  textAlign: 'center',
  width: '100%',
  backgroundColor: '#F9D8D3',
  fontSize: '12px',
  fontWeight: '600',
})

const ProductInformation: React.FC<Props> = ({ code, type, data, refetch }) => {
  const [amount, setAmount] = React.useState<number>(1)

  const { t } = useTranslation('detail')

  const fakeColors = [
    { value: 'xanh', label: 'Xanh' },
    { value: 'đen', label: 'Đen' },
    { value: 'trắng', label: 'Trắng' },
  ]

  const fakeSizes = [
    { value: 'L', label: 'L' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
  ]

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    console.log(formProps)
  }

  return (
    <Box className="max-w-[460px] w-full">
      <Box
        className="text-content-18 font-bold text-ellipsis overflow-hidden whitespace-nowrap mb-6"
        component={'div'}
        title={data?.name}
      >
        {data?.name}
      </Box>
      <Box className="px-7 py-4 bg-[#F2F4F5] rounded-xl mb-6 relative flex items-center gap-4 flex-wrap">
        <Box className="text-content-24 text-black-1 font-bold">
          {GeneralUtil.toMoney(data?.sale_price || data?.price)}
        </Box>
        {data?.discount && <Box className="line-through">{GeneralUtil.toMoney(data?.price)}</Box>}
        {data?.discount && (
          <Box className="absolute top-0 right-0 bg-access-red-2 text-white text-content-16 py-[14px] px-[8px] rounded-tr-xl rounded-bl-xl  ">
            {'-' + data?.discount + '%'}
          </Box>
        )}
      </Box>
      <Box className="flex flex-col gap-3 mb-6">
        <Box className="flex text-content-14 gap-5">
          <Box className="max-w-[130px] w-full">{t('brand')}</Box>
          <Box
            component={'div'}
            title={data?.brand || 'MANGO'}
            fontWeight={600}
            className="text-ellipsis overflow-hidden whitespace-nowrap"
          >
            {data?.brand || 'MANGO'}
          </Box>
        </Box>
        <Box component={'form'} onSubmit={handleSubmit}>
          <Box className="flex text-content-14 gap-5 items-center">
            <Box className="max-w-[130px] w-full">{t('colors')}</Box>
            <Box component={'div'} fontWeight={600}>
              <RadioGroup row defaultValue={fakeColors[0]?.value} name="color">
                {fakeColors?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item?.value}
                    control={<CheckedControl size="small" nameOfCheck={item?.value} typeSelect="check" />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Box>
          <Box className="flex text-content-14 gap-5 items-center">
            <Box className="max-w-[130px] w-full">{t('sizes')}</Box>
            <Box component={'div'} fontWeight={600}>
              <RadioGroup row defaultValue={fakeColors[0]?.value} name="size">
                {fakeSizes?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item?.value}
                    control={<CheckedControl size="small" typeSelect="label" nameOfCheck={item.label} />}
                    label={''}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Box>
          <Box className="flex text-content-14 gap-5 items-center">
            <Box className="max-w-[130px] w-full">{t('amount')}</Box>
            <Box component={'div'} fontWeight={600} className="flex !rounded-full p-2 border border-[#3a3a3c]">
              <Box
                className="!rounded-full !w-5 !h-5 !p-0 border flex items-center justify-center cursor-pointer border-[#3a3a3c]"
                onClick={() => setAmount(prev => prev + 1)}
              >
                +
              </Box>
              <Box
                component={'input'}
                type="number"
                name="amount"
                className="text-center outline-none max-w-[40px]"
                min={0}
                value={amount}
                onChange={e => setAmount(Number(e.target.value))}
              />
              <Box
                className="!rounded-full !w-5 !h-5 !p-0 border flex items-center justify-center cursor-pointer border-[#3a3a3c]"
                onClick={() => {
                  if (amount == 0) return
                  setAmount(prev => prev - 1)
                }}
              >
                -
              </Box>
            </Box>
          </Box>
          <Box className="flex text-content-14 gap-5 items-center mt-2">
            <Box className="max-w-[130px] w-full">{t('voucher')}</Box>
            <Box component={'div'} fontWeight={600}>
              <Select
                name="voucher"
                defaultValue={''}
                displayEmpty={true}
                className="!text-content-14 w-[200px] !rounded-lg"
                sx={{
                  '& .MuiOutlinedInput-input': {
                    padding: '4px 16px',
                  },
                }}
              >
                <MenuItem value={''} disabled sx={{ display: 'none' }}>
                  {t('select_voucher')}
                </MenuItem>
                <MenuItem value={10} className="!text-content-14">
                  Ten
                </MenuItem>
                <MenuItem value={20} className="!text-content-14">
                  Twenty
                </MenuItem>
                <MenuItem value={30} className="!text-content-14">
                  Thirty
                </MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <Box className="flex flex-wrap gap-4 justify-center mb-[80px] mt-5">
          <Button
            type="submit"
            startIcon={<LocalMallOutlined />}
            className="!bg-brand-1 !text-white !text-content-12 h-12 !rounded-xl max-w-[210px] w-full !font-bold"
          >
            {t('buy_now')}
          </Button>
          <Button
            variant="outlined"
            className=" !text-black-1 !text-content-12 h-12 !rounded-xl max-w-[210px] w-full !font-bold !border-black-1"
            startIcon={<ShoppingCartOutlined />}
          >
            {t('add_to_card')}
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box className="flex flex-wrap justify-between mt-3">
        <Box className="text-content-14 text-grey-2">
          {t('code_news')} {code}
        </Box>
        <Social data={data} refetch={refetch} />
      </Box>
    </Box>
  )
}

export default ProductInformation
